import { flattenSearchParams, QueryParamsEnum, TripType, currentTripStateIdVar, PriceDisplayMode, LocalStorageEnum, } from '@honeycomb/data';
import { QueryParamManager } from '../utils/QueryParamManager';
import { router } from '../utils/router';
function getRouteUrl(searchParameters, hotelId) {
    let newRoute = '';
    if (searchParameters.tripType === TripType.Flight) {
        newRoute = '/flights';
    }
    else if (searchParameters.tripType === TripType.Transfer) {
        newRoute = '/transfers/options';
    }
    else if (searchParameters.tripType === TripType.Holiday) {
        newRoute = hotelId ? `/holidays/hotels/${hotelId}` : '/holidays/hotels';
    }
    else {
        newRoute = hotelId ? `/hotels/${hotelId}` : '/hotels';
    }
    return newRoute;
}
function removeUrlParam(paramManager) {
    const remainedParam = paramManager;
    remainedParam.removeMultiple([
        QueryParamsEnum.CHECK_IN_DATE,
        QueryParamsEnum.CHECK_OUT_DATE,
        QueryParamsEnum.DESTINATIONS,
        QueryParamsEnum.DESTINATION_IDS,
        QueryParamsEnum.DESTINATION_ID,
        QueryParamsEnum.HOTEL_IDS,
        QueryParamsEnum.HOTEL_ID,
        QueryParamsEnum.ROOMS,
        QueryParamsEnum.TRIP_TYPE,
        QueryParamsEnum.DEPARTURE_AIRPORTS,
        QueryParamsEnum.CHILD_AGES,
        QueryParamsEnum.ADULTS,
        QueryParamsEnum.ARRIVAL_AIRPORT,
        QueryParamsEnum.CABIN,
        QueryParamsEnum.DURATION,
        QueryParamsEnum.FLEXIBLE_DAYS,
        QueryParamsEnum.DATE_SEARCH_TYPE,
        QueryParamsEnum.PICK_UP_POINT,
        QueryParamsEnum.DROP_OFF_POINT,
        QueryParamsEnum.FLIGHT_JOURNEY_TYPE,
    ]);
    return remainedParam;
}
// [feature]Route handles the logic, has a defined input/output, its the thing we write tests for
export function searchCreatedRoute(searchParameters, retainSortAndFilters = false) {
    var _a, _b, _c, _d, _e, _f, _g;
    const tripStateId = currentTripStateIdVar();
    const hotelId = (_b = (_a = searchParameters.hotelIds) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : (((_c = searchParameters.destinations) === null || _c === void 0 ? void 0 : _c.length) === 1 && ((_e = (_d = searchParameters.destinations) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.type) === 1
        ? (_g = (_f = searchParameters.destinations) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.id
        : 0);
    const newRoute = getRouteUrl(searchParameters, hotelId);
    const paramManager = new QueryParamManager(newRoute, router.currentParams());
    paramManager.remove(QueryParamsEnum.TRIP_STATE_ID);
    if (tripStateId) {
        paramManager.add(QueryParamsEnum.TRIP_STATE_ID, tripStateId);
    }
    // Remove all old search params so that nothing gets mixed up
    const newParamManager = removeUrlParam(paramManager);
    if (!retainSortAndFilters) {
        paramManager.removeMultiple([
            QueryParamsEnum.HOTELFILTERS,
            QueryParamsEnum.FLIGHTFILTERS,
            QueryParamsEnum.SORT,
            QueryParamsEnum.BOARD_OPTION_ID,
        ]);
    }
    if (searchParameters) {
        newParamManager.addMultiple(flattenSearchParams(searchParameters));
    }
    // Whenever the search is clicked, add the pre-selected local storage value as query param if price
    // per person.
    const priceDisplayMode = window.localStorage.getItem(LocalStorageEnum.PRICE_DISPLAY_MODE);
    if (priceDisplayMode === PriceDisplayMode.PerPersonPrice) {
        paramManager.add(QueryParamsEnum.PRICE_DISPLAY_MODE, priceDisplayMode);
    }
    return [newParamManager.toString(), undefined];
}
// [feature]Routing wraps the function above, its the thing we call from our hooks
export function searchCreatedRouting(params, replaceCurrentUrl = false, retainSortAndFilters = false) {
    if (params) {
        if (replaceCurrentUrl) {
            router.replace(params, () => searchCreatedRoute(params, retainSortAndFilters));
        }
        else {
            router.push(params, () => searchCreatedRoute(params, retainSortAndFilters));
        }
    }
}
export function getDetailsPageUrl(param, estabId) {
    const newRoute = getRouteUrl(param, estabId);
    const paramManager = new QueryParamManager(newRoute, router.currentParams());
    const newParamManager = removeUrlParam(paramManager);
    newParamManager.add(QueryParamsEnum.TRIP_STATE_ID, param.tripStateId);
    newParamManager.addMultiple(flattenSearchParams(param));
    return newParamManager.toString();
}
// update search url with new trip and new params without updating reactive var.
export function updateTripStateRouting(tripStateId, searchParameters) {
    var _a;
    const params = new QueryParamManager((_a = router.currentRoute()) !== null && _a !== void 0 ? _a : '/', router.currentParams());
    if (tripStateId) {
        params.remove(QueryParamsEnum.TRIP_STATE_ID);
        params.add(QueryParamsEnum.TRIP_STATE_ID, tripStateId);
    }
    if (searchParameters) {
        // Remove all the other search query params
        removeUrlParam(params);
        // Read all the new searchParams
        params.addMultiple(flattenSearchParams(searchParameters));
    }
    router.push(router.currentRoute(), () => [params.toString(), undefined, { shallow: true }]);
}
